import { ESignSigner } from '../esign';
import {
  ProposedInsuredEntity,
  OwnerEntity,
  PayerEntity,
  AdvisorEntity,
  ProductsEntity,
  AnnuitantEntity,
} from '../insuranceEntities';
import { Application } from '../models/application';
import { ApplicationPointOfSaleDecisions } from '../pointOfSaleDecision';
import { ProductAddon } from '../products';
import { QuestionnaireBlueprint } from '../questionnaireBlueprints';

export type DynamicPdfDataGetRequest<TQuestionnaire, TAnswerPath> = {
  application: Application;
  questionnaire: TQuestionnaire;
  blueprint: QuestionnaireBlueprint;
  nodeIdToAnswerPathMap: [string, TAnswerPath][];
  entities: DynamicPdfInsuranceEntities;
  eSignSigners: ESignSigner[] | undefined;
  addons: ProductAddon[] | undefined;
  pointOfSaleDecisions: ApplicationPointOfSaleDecisions;
};

export type DynamicPdfInsuranceEntities = {
  proposedInsureds: ProposedInsuredEntity[];
  owners: OwnerEntity[];
  payers: PayerEntity[];
  advisors: AdvisorEntity[];
  annuitant: AnnuitantEntity | null;
  products: ProductsEntity;
};

// TODO:  Refactor typing: https://breathelife.atlassian.net/browse/DEV-10756
export enum DynamicPdfType {
  insured = 'user',
  carrier = 'carrier',
  advisor = 'advisor',
}
