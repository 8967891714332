import { DataLabel } from '@breathelife/meta-cruncher';
import { failure, Result, success } from '@breathelife/result';

import { IsoCountryCode } from '../applications/isoCountryCode';
import { ApplicationMode } from '../applications/mode';
import { IconName } from '../icons';
import { Localizable } from '../localization/localization';
import { ParticipantRoles } from '../participant';
import { PdfDocumentType } from '../carrierQuestionnaire/documentType';
import { EngineEffects } from '../carrierQuestionnaire/engineEffects';
import { FieldLayout } from '../carrierQuestionnaire/fieldLayout';
import { FieldTypes } from '../carrierQuestionnaire/fieldTypes';
import { InsuranceModule } from '../carrierQuestionnaire/insuranceModule';
import { OptionSize } from '../carrierQuestionnaire/optionSize';
import { PlatformType } from '../carrierQuestionnaire/platformType';
import { SubsectionVariant } from '../carrierQuestionnaire/subsectionVariant';
import { BlueprintConditionsValueWithMessage, BlueprintConditionValue } from './conditionBlueprints';
import { ReferenceLabel, ReferenceLabelValidationError } from './ReferenceLabel';
import { QuoterSubsectionBlueprintVariant, SubsectionVariantBlueprint } from './subsectionVariantBlueprints';

export enum QuestionnaireBlueprintRenderOn {
  web = 'web',
  pdf = 'pdf',
  summary = 'summary',
}

export enum NumberFieldValidation {
  integer = 'integer',
  decimal = 'decimal',
  percentage = 'percentage',
}

export enum MoneyFieldValidation {
  integer = 'integer',
  decimal = 'decimal',
}

export enum DateFieldValidation {
  date = 'date',
  pastDate = 'pastDate',
  yearMonth = 'yearMonth',
  yearMonthPastDate = 'yearMonthPastDate',
  yearMonthFutureDate = 'yearMonthFutureDate',
  yearMonthPastOrCurrentDate = 'yearMonthPastOrCurrentDate',
  yearMonthFutureOrCurrentDate = 'yearMonthFutureOrCurrentDate',
  futureDate = 'futureDate',
  futureOrCurrentDate = 'futureOrCurrentDate',
  pastOrCurrentDate = 'pastOrCurrentDate',
  currentDate = 'currentDate',
}

export enum BooleanFieldValidation {
  boolean = 'boolean',
  booleanTrue = 'booleanTrue',
}

export enum StringFieldValidation {
  string = 'string',
}

export enum PhoneFieldValidation {
  phone = 'phone',
}

export enum AlwaysValid {
  signature = 'signature',
}

export enum InputFieldValidation {
  string = 'string',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  middleName = 'middleName',
  sin = 'sin',
  canadianPostalCode = 'canadianPostalCode',
  zipCode = 'zipCode',
  branchNumber = 'branchNumber',
  institutionNumber = 'institutionNumber',
  accountNumber = 'accountNumber',
  ssn = 'ssn',
}

// TODO: move InfoVariants out of shared/questionnaire and use that.
export enum InformationFieldBlueprintVariant {
  warning = 'warning',
  error = 'error',
  success = 'success',
  info = 'info',
  paragraph = 'paragraph',
}

export enum QuestionnaireBlueprintCopyableOption {
  none = 'none',
  insured = 'insured',
  owner = 'owner',
}

// #region Type Definition
export type DynamicOptionsBlueprint = {
  collection: string;
  select: string[];
  visible?: BlueprintConditionValue;
};

export type FieldBlueprintBase = QuestionnaireBlueprintBase & {
  answerNodeId: string;
  valid?: BlueprintConditionsValueWithMessage[];
  optional?: boolean;
  disabled?: boolean;
  infoSupplement?: InfoSupplementBlueprint;
  placeholder?: Partial<Localizable>;
  iconName?: IconName;
  triggerStepNavigation?: boolean;
  layout?: FieldLayout;
  displayInCardPreview?: boolean;
  dynamicOptions?: DynamicOptionsBlueprint;
  internal?: {
    relatesTo?: string;
    computedValue?: Record<string, unknown>; // TODO update typing when we add support for queries in parts
  };
  effects?: EngineEffects;
  applicationModes?: ApplicationMode[];
};

export const getFieldBlueprintBase = (field: FieldBlueprint): FieldBlueprintBase => {
  // This function is usefule to go from one field type to another one.
  // You transfer everything that is shared amongst them, but nothing more.
  // That would alleviate some of the pressure of properties are kept on
  // fields when we change the field.type. (Would prefer constructor for each one tho)
  const fieldWithKeysEvenIfUndefined: FieldBlueprintBase = {
    id: field.id,
    partName: field.partName,
    referenceLabel: field.referenceLabel,
    text: field.text,
    title: field.title,
    visible: field.visible,
    dataLabel: field.dataLabel,
    platforms: field.platforms,
    renderOn: field.renderOn,
    hidden: field.hidden,
    isCustom: field.isCustom,
    copyable: field.copyable,
    answerNodeId: field.answerNodeId,
    valid: field.valid,
    optional: field.optional,
    disabled: field.disabled,
    infoSupplement: field.infoSupplement,
    placeholder: field.placeholder,
    iconName: field.iconName,
    triggerStepNavigation: field.triggerStepNavigation,
    layout: field.layout,
    displayInCardPreview: field.displayInCardPreview,
    dynamicOptions: field.dynamicOptions,
    internal: field.internal,
    effects: field.effects,
    applicationModes: field.applicationModes,
  };

  Object.keys(fieldWithKeysEvenIfUndefined).forEach((key: string) => {
    if ((fieldWithKeysEvenIfUndefined as Record<string, any>)[key] === undefined) {
      delete (fieldWithKeysEvenIfUndefined as Record<string, any>)[key];
    }
  });

  return fieldWithKeysEvenIfUndefined;
};

export type InputFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.input;
  validateAs: InputFieldValidation;
  defaultValue?: string;
};

export type NumberFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.number;
  validateAs: NumberFieldValidation;
  numericalDataType?: 'float' | 'integer';
  defaultValue?: number;
};

export type MoneyFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.money;
  validateAs: MoneyFieldValidation;
  defaultValue?: number;
};

export type DateFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.date;
  validateAs: DateFieldValidation;
};

export type CheckboxFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.checkbox;
  validateAs: BooleanFieldValidation;
  defaultValue?: boolean;
};

export type TextareaFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.textarea;
  validateAs: StringFieldValidation;
  defaultValue?: string;
};

export type PhoneFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.phone;
  validateAs: PhoneFieldValidation;
};

export type InformationFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.information;
  validateAs: StringFieldValidation;
  variant: InformationFieldBlueprintVariant;
};

export type ButtonFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.button;
  validateAs: BooleanFieldValidation;
  buttonText: Partial<Localizable>;
};

export type CurrencyCardFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.currencyCard;
  validateAs: NumberFieldValidation;
};

export type InfoSupplementBlueprint = {
  text: Partial<Localizable>;
  title?: Partial<Localizable>;
  image?: {
    // This is the only image we support right now, and it only works for certain carriers...
    name: 'cheque';
  };
};

export type AddressAutocompleteFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.autocomplete;
  validateAs: StringFieldValidation;
  countryCode: IsoCountryCode;

  // When the `addressAutocompleteFields.streetAddress` is not set we still need to store the autocomplete data somewhere.
  addressAutocompleteNodeId?: string;

  // Store the partNames of the blueprints associated with this addressAutocomplete blueprint.
  addressAutocompleteFields: {
    streetAddress?: string;
    city: string;
    stateOrProvince: string;
    postalCodeOrZip: string;
  };
};

export type RadioFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.radio;
  validateAs: StringFieldValidation | BooleanFieldValidation;
  selectOptions: SelectOptionBlueprint[];
  optionSize?: OptionSize;
  defaultValue?: string | boolean;
};

export type AgreeFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.agree;
  validateAs: BooleanFieldValidation;
  confirmedLabel: Partial<Localizable>;
  modalHeader: Partial<Localizable>;
  modalText: Partial<Localizable>;
};

export type SignatureFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.signature;
  validateAs: AlwaysValid.signature;
  participantRole: ParticipantRoles;
};

export type SectionGroupKey = string;

export type QuestionnaireBlueprintBase = {
  id: string;
  referenceLabel?: string;
  partName: string;
  text?: Partial<Localizable>;
  title?: Partial<Localizable>;
  visible?: BlueprintConditionValue;
  dataLabel?: DataLabel;
  platforms?: PlatformType[];
  renderOn?: QuestionnaireBlueprintRenderOn[];
  hidden?: boolean;
  isCustom?: boolean;
  copyable?: QuestionnaireBlueprintCopyableOption;
};

export const DEFAULT_BLUEPRINT: QuestionnaireBlueprint = {
  sectionBlueprints: [],
  sectionGroupBlueprints: {
    insuredPeople: { id: '24664fee-5bfa-4862-9049-42b636d5686c', partName: 'insuredPeople' },
  },
};

export type QuestionnaireBlueprint = {
  sectionBlueprints: SectionBlueprint[];
  sectionGroupBlueprints: Record<SectionGroupKey, SectionGroupBlueprint>;
};

export type SectionGroupBlueprint = QuestionnaireBlueprintBase & {
  repeatable?: {
    repeatableAnswerNodeId: string;
    minRepeatable: number;
    maxRepeatable: number;
  };
  effects?: EngineEffects;
};

export type SectionBlueprint = QuestionnaireBlueprintBase & {
  sectionGroupKey: SectionGroupKey;
  subsections: SubsectionBlueprint[];
  pdfDocuments?: PdfDocumentType[];
  modules?: InsuranceModule[];
  iconName?: IconName;
};

export type SubsectionBlueprint = QuestionnaireBlueprintBase & {
  questions: QuestionBlueprint[];
  nextStepButtonText?: Partial<Localizable>;
  variant?: SubsectionVariantBlueprint;
  iconName?: IconName;
  internal?: {
    variant?: SubsectionVariant;
    variantOptions?: { useApplicationMonthlyPremium?: boolean };
  };
  showInNavigation?: boolean;
  pageBreakSubSectionInPdf?: boolean;
};

export type QuoterSubsectionBlueprint = SubsectionBlueprint & {
  variant: QuoterSubsectionBlueprintVariant;
};

export type QuestionBlueprint = QuestionnaireBlueprintBase & {
  fields: FieldBlueprint[];
  repeatable?: {
    repeatableAnswerNodeId: string;
    addButtonText: Partial<Localizable>;
    removeButtonText: Partial<Localizable>;
    minRepeatable: number;
    maxRepeatable: number;
  };
  internal?: { showInPdfWithoutFields?: boolean };
  displayAsCard?: boolean;
  effects?: EngineEffects;
};

export type FieldTypeValidation =
  | NumberFieldValidation
  | MoneyFieldValidation
  | DateFieldValidation
  | BooleanFieldValidation
  | StringFieldValidation
  | PhoneFieldValidation
  | InputFieldValidation;

export type SelectOptionBlueprint = {
  partName: string;
  hidden?: boolean;
  isCustom?: boolean;
  text: Partial<Localizable>;
  visible?: BlueprintConditionValue;
  internal?: {
    platformTypeFilter?: PlatformType[];
    checked?: boolean;
  };
  orderingIndex?: number;
};

export type SelectOptionsApplicationContextBlueprint = {
  tag?: string;
  labelKey?: Partial<Localizable>;
  valuePath?: string;
};

export type SelectOptionFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.dropdown;
  selectOptions: SelectOptionBlueprint[];
  selectOptionsApplicationContext?: SelectOptionsApplicationContextBlueprint;
  validateAs: StringFieldValidation;
  defaultValue?: string | string[];
  searchable?: boolean;
};

export type CheckBoxGroupFieldBlueprint = FieldBlueprintBase & {
  fieldType: FieldTypes.checkboxGroup;
  selectOptions: SelectOptionBlueprint[];
  validateAs: StringFieldValidation;
  defaultValue?: string | string[];
};

export type FieldBlueprint =
  | InputFieldBlueprint
  | SelectOptionFieldBlueprint
  | NumberFieldBlueprint
  | MoneyFieldBlueprint
  | DateFieldBlueprint
  | CheckboxFieldBlueprint
  | CheckBoxGroupFieldBlueprint
  | TextareaFieldBlueprint
  | PhoneFieldBlueprint
  | InformationFieldBlueprint
  | ButtonFieldBlueprint
  | CurrencyCardFieldBlueprint
  | AddressAutocompleteFieldBlueprint
  | RadioFieldBlueprint
  | AgreeFieldBlueprint
  | SignatureFieldBlueprint;

export type FieldValidation =
  | InputFieldValidation
  | NumberFieldValidation
  | MoneyFieldValidation
  | DateFieldValidation
  | BooleanFieldValidation
  | StringFieldValidation
  | PhoneFieldValidation
  | AlwaysValid;

export type QuestionnaireElementBlueprint = SectionBlueprint | SubsectionBlueprint | QuestionBlueprint | FieldBlueprint;

export function isSectionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint,
): questionnaireElementBlueprint is SectionBlueprint {
  return !!(questionnaireElementBlueprint as SectionBlueprint).subsections;
}

export function isSubsectionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint,
): questionnaireElementBlueprint is SubsectionBlueprint {
  return !!(questionnaireElementBlueprint as SubsectionBlueprint).questions;
}

export function isQuestionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint,
): questionnaireElementBlueprint is QuestionBlueprint {
  return !!(questionnaireElementBlueprint as QuestionBlueprint).fields;
}

export function isFieldBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint,
): questionnaireElementBlueprint is FieldBlueprint {
  return !!(questionnaireElementBlueprint as FieldBlueprint).fieldType;
}

export function isSelectOptionBlueprint(
  questionnaireElementBlueprint: QuestionnaireElementBlueprint | SelectOptionBlueprint,
): questionnaireElementBlueprint is SelectOptionBlueprint {
  return (
    !isSectionBlueprint(questionnaireElementBlueprint) &&
    !isSubsectionBlueprint(questionnaireElementBlueprint) &&
    !isQuestionBlueprint(questionnaireElementBlueprint) &&
    !isFieldBlueprint(questionnaireElementBlueprint)
  );
}

export type SelectOptionFieldBlueprintType = FieldTypes.dropdown | FieldTypes.radio | FieldTypes.checkboxGroup;

export function isSelectOptionFieldBlueprintType(fieldType: FieldTypes): fieldType is SelectOptionFieldBlueprintType {
  return fieldType === FieldTypes.dropdown || fieldType === FieldTypes.radio || fieldType === FieldTypes.checkboxGroup;
}

export function isSelectOptionFieldBlueprint(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is SelectOptionFieldBlueprint {
  return (
    fieldBlueprint.fieldType === FieldTypes.dropdown ||
    fieldBlueprint.fieldType === FieldTypes.radio ||
    fieldBlueprint.fieldType === FieldTypes.checkboxGroup
  );
}

export function isCheckBoxGroupFieldBlueprint(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is CheckBoxGroupFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.checkboxGroup;
}

export function isDropdownFieldBlueprint(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is CheckBoxGroupFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.dropdown;
}

export function isButtonFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is ButtonFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.button;
}

export function isInformationFieldBlueprint(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is InformationFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.information;
}

export function isAddressAutocompleteFieldBlueprint(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is AddressAutocompleteFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.autocomplete;
}

export function isRadioFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is RadioFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.radio;
}

export function isAgreeFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is AgreeFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.agree;
}

export function isNumberFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is NumberFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.number;
}

export function isInputFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is InputFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.input;
}

export function isMoneyFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is MoneyFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.money;
}

export function isTextareaFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is TextareaFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.textarea;
}

export function isCheckboxFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is CheckboxFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.checkbox;
}

export function isSignatureFieldBlueprint(fieldBlueprint: FieldBlueprint): fieldBlueprint is SignatureFieldBlueprint {
  return fieldBlueprint.fieldType === FieldTypes.signature;
}

export function isQuoterSubsectionBlueprint(subsection: SubsectionBlueprint): subsection is QuoterSubsectionBlueprint {
  const quoterSubsection = subsection as QuoterSubsectionBlueprint;
  return quoterSubsection.variant?.type === SubsectionVariant.quoter && !!quoterSubsection.variant?.productNodeIds;
}

export function isTextFieldBlueprint(fieldType: FieldTypes): boolean {
  const textFieldTypes = [
    FieldTypes.input,
    FieldTypes.textarea,
    FieldTypes.money,
    FieldTypes.phone,
    FieldTypes.dropdown,
    FieldTypes.number,
    FieldTypes.autocomplete,
    FieldTypes.date,
  ];
  return textFieldTypes.includes(fieldType);
}

export type AcceptingDefaultValueFieldBlueprint =
  | InputFieldBlueprint
  | NumberFieldBlueprint
  | MoneyFieldBlueprint
  | CheckboxFieldBlueprint
  | TextareaFieldBlueprint
  | RadioFieldBlueprint
  | SelectOptionFieldBlueprint
  | CheckBoxGroupFieldBlueprint;

export function isAcceptingDefaultValueField(
  fieldBlueprint: FieldBlueprint,
): fieldBlueprint is AcceptingDefaultValueFieldBlueprint {
  return (
    !!(fieldBlueprint as AcceptingDefaultValueFieldBlueprint).defaultValue ||
    isCheckboxFieldBlueprint(fieldBlueprint) ||
    isTextareaFieldBlueprint(fieldBlueprint) ||
    isInputFieldBlueprint(fieldBlueprint) ||
    isMoneyFieldBlueprint(fieldBlueprint) ||
    isSelectOptionFieldBlueprint(fieldBlueprint) ||
    isNumberFieldBlueprint(fieldBlueprint) ||
    isRadioFieldBlueprint(fieldBlueprint) ||
    isCheckBoxGroupFieldBlueprint(fieldBlueprint)
  );
}

class DuplicateItemError<T> extends Error {
  item: T;
  constructor(item: T) {
    super();
    this.item = item;
  }
}

class ThrowingSet<T> {
  private readonly set: Set<T>;

  constructor() {
    this.set = new Set();
  }

  add(item: T): void {
    if (this.set.has(item)) {
      throw new DuplicateItemError(item);
    }
    this.set.add(item);
  }
}

export function everyIDsAreUnique(blueprint: QuestionnaireBlueprint): Result<string, void> {
  const existingIds = new ThrowingSet();

  try {
    if (blueprint.sectionGroupBlueprints.contract) {
      existingIds.add(blueprint.sectionGroupBlueprints.contract.id);
    }

    if (blueprint.sectionGroupBlueprints.insuredPeople) {
      existingIds.add(blueprint.sectionGroupBlueprints.insuredPeople.id);
    }

    blueprint.sectionBlueprints.forEach((section) => {
      existingIds.add(section.id);
      section.subsections.forEach((subsection) => {
        existingIds.add(subsection.id);
        subsection.questions.forEach((question) => {
          existingIds.add(question.id);
          question.fields.forEach((field) => {
            existingIds.add(field.id);
          });
        });
      });
    });
  } catch (e) {
    if (e instanceof DuplicateItemError && typeof e.item === 'string') {
      return failure(e.item);
    }
    return failure('unknown id');
  }

  return success(undefined);
}

export type QuestionnaireNodeLookup = Record<string, { nodeId?: string; blueprintId: string }>;

export function createQuestionnaireNodeLookup(
  blueprint: QuestionnaireBlueprint,
): Result<ReferenceLabelValidationError, QuestionnaireNodeLookup> {
  const result: QuestionnaireNodeLookup = {};

  const createAlreadyExistsError = (referenceLabel: string): ReferenceLabelValidationError =>
    new ReferenceLabelValidationError(`Reference labels must be unique and "${referenceLabel}" already exists.`);

  const createReferenceLabelMapping = (
    blueprintId: string,
    nodeId?: string,
  ): { blueprintId: string; nodeId?: string } => ({ blueprintId, nodeId });

  // Section Groups
  for (const sectionGroupKey in blueprint.sectionGroupBlueprints) {
    const sectionGroup = blueprint.sectionGroupBlueprints[sectionGroupKey];
    if (typeof sectionGroup !== 'object' || !sectionGroup?.referenceLabel) {
      continue;
    }

    const referenceLabel = ReferenceLabel.create(sectionGroup.referenceLabel);
    if (referenceLabel.success === false) {
      return failure(referenceLabel.error);
    }

    const alreadyExists = result[referenceLabel.value.value];
    if (alreadyExists) {
      return failure(createAlreadyExistsError(referenceLabel.value.value));
    }

    result[referenceLabel.value.value] = createReferenceLabelMapping(
      sectionGroup.id,
      sectionGroup.repeatable?.repeatableAnswerNodeId,
    );
  }

  // Sections
  for (const section of blueprint.sectionBlueprints) {
    if (section.referenceLabel) {
      const referenceLabel = ReferenceLabel.create(section.referenceLabel);
      if (referenceLabel.success === false) {
        return failure(referenceLabel.error);
      }

      const alreadyExists = result[referenceLabel.value.value];
      if (alreadyExists) {
        return failure(createAlreadyExistsError(referenceLabel.value.value));
      }

      result[referenceLabel.value.value] = createReferenceLabelMapping(section.id);
    }

    // Subsections
    for (const subsection of section.subsections) {
      if (subsection.referenceLabel) {
        const referenceLabel = ReferenceLabel.create(subsection.referenceLabel);
        if (referenceLabel.success === false) {
          return failure(referenceLabel.error);
        }

        const alreadyExists = result[referenceLabel.value.value];
        if (alreadyExists) {
          return failure(createAlreadyExistsError(referenceLabel.value.value));
        }

        result[referenceLabel.value.value] = createReferenceLabelMapping(subsection.id);
      }

      // Questions
      for (const question of subsection.questions) {
        if (question.referenceLabel) {
          const referenceLabel = ReferenceLabel.create(question.referenceLabel);
          if (referenceLabel.success === false) {
            return failure(referenceLabel.error);
          }

          const alreadyExists = result[referenceLabel.value.value];
          if (alreadyExists) {
            return failure(createAlreadyExistsError(referenceLabel.value.value));
          }

          result[referenceLabel.value.value] = createReferenceLabelMapping(
            question.id,
            question.repeatable?.repeatableAnswerNodeId,
          );
        }

        // Fields
        for (const field of question.fields) {
          if (field.referenceLabel) {
            const referenceLabel = ReferenceLabel.create(field.referenceLabel);
            if (referenceLabel.success === false) {
              return failure(referenceLabel.error);
            }

            const alreadyExists = result[referenceLabel.value.value];
            if (alreadyExists) {
              return failure(createAlreadyExistsError(referenceLabel.value.value));
            }

            result[referenceLabel.value.value] = createReferenceLabelMapping(field.id, field.answerNodeId);
          }
        }
      }
    }
  }

  return success(result);
}
