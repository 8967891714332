import _ from 'lodash';

import { ApplicationContext } from '../applicationContext';
import { ApplicationMode, ApplicationType, CarrierSubmissionStatus, SubmissionSource } from '../applications';
import { EmailStatus } from '../email';
import { ESignCeremony } from '../esign';
import { Language } from '../localization';
import { FileMeta } from '../messageQueue';
import { PaymentAttempt } from '../paymentAttempt';
import { LineOfBusinessName } from '../linesOfBusiness';
import { OutcomeCode } from '../carrierQuestionnaire';
import { PrivateData } from '../signatures';
import { ApplicationNote } from './applicationNote';
import { Lead } from './lead';

/**
 *  @deprecated 🚫🚨 DON'T READ OR WRITE 🚨🚫
 *  @author Team-EE (📞 Questions, problems or complains? call us! ☎️)
 *  @description
 *
 *  The answers structure produced by the NodeIdanswersResolver.usingNodeId().
 *
 *  It's the inner structure of an IAnswerResolver.
 *  To read or write from it, you need to use one of the classes that implements IAnswerResolver.
 *
 *  - NodeIdanswersResolver.usingNodeId().ts
 *  - BlueprintIdanswersResolver.usingNodeId().ts (Not yet available)
 *
 *  @example How to read
 *
 *  const answersResolver = new NodeIdAnswersResolver();
 *  const aValue = answersResolver.usingNodeId().get(answers, 'node-id');
 *
 *  @example How to write
 *
 *  const answersResolver = new NodeIdAnswersResolver()
 *  answersResolver.usingNodeId().save(answers, 'node-id', 'new value');
 *
 *  @description
 *
 *  There's a lot of work currently involving this structure. In
 *  the future this 'answers' object will not be available to
 *  the caller (users/devs). Building stuff by reading this transparent
 *  structure should be discouraged.
 *
 *  The current design is leaking implementation's details.
 */
export type Answers = Record<string, any>;

export class VersionedAnswers {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly __tag = 'VersionedAnswers' as const;
  public readonly v1: Answers;
  public readonly v2: Answers;

  constructor(answers: { v1: Answers; v2: Answers }) {
    this.v1 = JSON.parse(JSON.stringify(answers.v1)); // Create it owns pointer for answers.
    this.v2 = JSON.parse(JSON.stringify(answers.v2)); // Create it owns pointer for answers.
  }

  public isEqual(otherAnswers: VersionedAnswers | undefined): boolean {
    return _.isEqual(this.v1, otherAnswers?.v1) && _.isEqual(this.v2, otherAnswers?.v2);
  }
}

export type LegacyApplicationComputedProps<TStepType = unknown> = {
  ['payment-attempts']?: PaymentAttempt[] | null;
  steps?: TStepType[];
  progress?: number;
};

export type EncryptedApplication = ApplicationBase & {
  encryptedAnswers: Buffer | null;
  encryptedAnswersV2: Buffer | null;
};

export type Application<TStepType = unknown> = ApplicationBase<TStepType> & {
  answers: Answers;
  answersV2: Answers;
};

export type ApplicationBase<TStepType = unknown> = LegacyApplicationComputedProps<TStepType> & {
  /** @deprecated Please use the files service to handle application files */
  carrierPdf?: FileMeta;
  carrierSubmissionStatus?: CarrierSubmissionStatus | null;
  confirmationNumber?: string;
  coverageAmount?: number | null;
  createdAt?: Date;
  createdAtQuestionnaireVersionId: string;
  currentPaymentAttemptId?: string;
  currentQuestionnaireVersionId: string;
  defaultProducts?: Record<string, any>[];
  emailStatus?: EmailStatus;
  eSignCeremonies?: ESignCeremony[];
  externalData?: { [key: string]: any };
  gitRevision?: string;
  id: string;
  ineligibleAt: string | null;
  isDeclarationSent: boolean;
  isTest?: boolean;
  lang: Language;
  leadId?: number;
  needsAnalysisId?: string;
  notes?: ApplicationNote;
  /** @deprecated Please refer to the point of sale decision outcome from (point-of-sale-decision).*/
  outcome?: OutcomeCode;
  /** @deprecated Please use the files service to handle application files */
  pdf?: FileMeta;
  premium?: number;
  private: PrivateData;
  product: string;
  productLine: string;
  questionnaireVersion?: string;
  recommendedCoverageAmount?: number;
  refNo: string;
  selectedAddons?: string[];
  signed: boolean;
  submissionDate?: Date | null;
  submissionToCarrierDate?: Date | null;
  submitted: boolean;
  type?: ApplicationType; // This value will always be set for new applications, but some older ones will not have it
  updatedAt?: Date;
  refNoIncrement?: number | null;
  /** @deprecated Please use the files service to handle application files */
  externalPdf: FileMeta | null;
  isPreview: boolean;
  timezone: string | null;
  lead?: Lead;
  mode: ApplicationMode;
  /** @deprecated Please use leads status to handle application status */
  status?: string;
  submissionSource?: SubmissionSource;
  submissionRunId?: string | null;
  lineOfBusiness: LineOfBusinessName;
  applicationContext?: ApplicationContext;
  mgaId?: string;
  answersUpdatedAt: Date | null;
};

export type ExternalApplicationData = {
  insured: Insured;
  product: Product[];
  payment?: Payment;
  leadId: number;
  policy: Policy;
  agent: Agent;
  language?: Language;
};

export type Policy = {
  signatureState: string;
  isMec?: boolean;
};
export type Agent = {
  agentNpn: string;
};
export type Insured = {
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth: string;
  age?: number;
  gender?: string;
  address?: string;
  address2?: string;
  city?: string;
  zip?: string;
  provinceOrState?: string;
  holdingCourt?: string;
  email: string;
};

export type Payment = {
  initialDeposit?: number;
  taxStatus?: string;
  withdrawInterest?: boolean;
  withdrawalsAmount?: number;
  withdrawalsStart?: number;
  withdrawalsEnd?: number;
  depositsAmount?: number;
  depositsStart?: number;
  depositsEnd?: number;
};

export type Product = {
  planCode: string;
  isMec?: boolean;
  annualPremium: number;
  modalPremium: number;
  modalPremiumIncludesRidersPremium: boolean;
  riskClass?: string;
  faceAmount: number;
  modal: string;
  dividends?: string;
  tableRating?: string;
  flatRatingAmount?: number;
  flatRatingEnd?: number;
  riders: Riders[];
};

export type Riders = {
  code: string;
  annualPremium?: number;
  riskClass?: string;
  faceAmount?: number;
  basisPoint?: number;
  tableRating?: string;
  flatRatingAmount?: number;
  flatRatingEnd?: number;
};

export enum ExternalProductType {
  juvenileTermLife = 'juvenileTermLife',
  simplifiedIssueWholeLife = 'simplifiedIssueWholeLife',
  simplifiedIssueSinglePremiumWholeLife = 'simplifiedIssueSinglePremiumWholeLife',
  ordinaryWholeLife = 'ordinaryWholeLife',
  ordinaryTermLife = 'ordinaryTermLife',
}

export type ApplicationUnderwritingReportBase = {
  id: string;
  applicationId: string;
  participantId: string;
  type: string;
  provider: string;
  createdAt: Date;
  updatedAt: Date;
};

export type DatabaseApplicationUnderwritingReport = ApplicationUnderwritingReportBase & {
  request: Buffer | null;
  response: Buffer | null;
};

export type ApplicationUnderwritingReport = ApplicationUnderwritingReportBase & {
  request: string | null;
  response: string | null;
};

export type ApplicationUnderwritingReportMinimumFields =
  | 'applicationId'
  | 'participantId'
  | 'type'
  | 'provider'
  | 'request'
  | 'response';

export type ApplicationUpdateWithAnswers = Partial<Omit<Application, 'answers' | 'answersV2' | 'answersUpdatedAt'>> & {
  answers: Answers;
  answersV2: Answers;
  answersUpdatedAt: Date | null;
};

type ApplicationUpdateWithoutAnswers = Partial<Omit<Application, 'answers' | 'answersV2' | 'answersUpdatedAt'>> & {
  answers?: never;
  answersV2?: never;
  answersUpdatedAt?: never;
};

export type ApplicationUpdateData = ApplicationUpdateWithAnswers | ApplicationUpdateWithoutAnswers;
