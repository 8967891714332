import { z } from 'zod';
import { PaymentFrequency } from '../products/insuranceProductPricing';
import { Answers } from '../models';

export type QuotePerProduct = Record<string, number | null>;

export const quoteInfoSchema = z.object({
  quotePerProduct: z.record(z.number().nullable(), z.string()),
  paymentFrequency: z.nativeEnum(PaymentFrequency),
  annualPremium: z.number().optional().nullable(),
  equivalentSingleAge: z
    .object({
      nodeId: z.string(),
      value: z.number(),
      insuredIndex: z.number(),
    })
    .optional(),
  equivalentGender: z.union([z.literal('male'), z.literal('female')]).optional(),
  equivalentSmokerStat: z.union([z.literal('smoker'), z.literal('nonSmoker')]).optional(),
  answers: z.array(z.object({ nodeId: z.string(), value: z.number(), insuredIndex: z.number() })).optional(),
});

export type QuoteInfo = z.infer<typeof quoteInfoSchema>;

export type FetchQuotesRequest = {
  appId: string;
  coverageAmount?: number | null;
  answers: Answers;
  answersV2: Answers;
  includeADO?: boolean;
  includeESA?: boolean;
};

export type TotalPremiums = {
  monthly: number;
  annual: number;
  products?: {
    productCode: string;
    monthly: number;
    annual: number;
  }[];
};
