export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends any[] ? T[P] : RecursivePartial<T[P]>;
};

export type DeepPartial<T> = T extends Function ? T : T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export type DeepString<T> = T extends string ? T : T extends object ? { [P in keyof T]: DeepString<T[P]> } : T;

export type Base64 = string;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

// KeepTypeAlias - Explanation
// --------------------------------------------------------------------------
// As an example, in the file: backend/types/src/models/applicationContext.ts
// We infer the type with the zod utility like this:
//   export type ApplicationContext = z.infer<typeof ApplicationContextSchema>>;
//
// If we use that type in a function like this:
//   const abc = (contextA: ApplicationContext, contextB: ApplicationContext) => console.log('abc');
//
// Then the LSP information is hard to read/understand by callers. If you put your mouse over the function call, you get this:
//   abc(contextA: { id: string; applicationId: string; carrierSpecificDataId: string; createdAt: Date; updatedAt: Date; }, contextB: { id: string; applicationId: string; carrierSpecificDataId: string; createdAt: Date; updatedAt: Date; }): void
//
// Instead if you use the KeepTypeAlias<T> over the infer zod type.
//   export type ApplicationContext = KeepTypeAlias<z.infer<typeof ApplicationContextSchema>>;
//
// Then you get a much more developer friendly LSP information which is much more readable:
//   abc(contextA: ApplicationContext, contextB: ApplicationContext): void
export type KeepTypeAlias<T extends Record<string, unknown>> = Omit<{ hack: never }, 'hack'> & {
  [Property in keyof T]: T[Property];
};

type ValueOf<TObj> = TObj[keyof TObj];
type OneOnly<TObj, TKey extends keyof TObj> = { [key in Exclude<keyof TObj, TKey>]+?: undefined } & Pick<TObj, TKey>;
type OneOfByKey<TObj> = { [key in keyof TObj]: OneOnly<TObj, key> };
export type OneOfType<TObj> = ValueOf<OneOfByKey<TObj>>;

export const keysOf = <T extends Record<string, unknown>>(object: T): (keyof T)[] => {
  return Object.keys(object) as (keyof T)[];
};
export type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export type ISO_8601_String = string;
