import z from 'zod';
import { KeepTypeAlias } from '../utilities';
import { BlueprintId, NodeId } from './types';

export const InstanceIndexSchema = z.number();
export type InstanceIndex = z.infer<typeof InstanceIndexSchema>;

export const InstanceIdentifierSchema = z.string();
export type InstanceIdentifier = z.infer<typeof InstanceIdentifierSchema>;

export const InstanceScopeSchema = z.record(InstanceIdentifierSchema, InstanceIndexSchema);
export type InstanceScope = KeepTypeAlias<z.infer<typeof InstanceScopeSchema>>;

export type BothInstanceScope = { byBlueprintId: InstanceScope; byNodeId: InstanceScope };

export type NodeIdInstanceScope = InstanceScope;
export type BlueprintIdInstanceScope = InstanceScope;

// TODO: Change name NodeInstance to AnswerInstance | Instance...
export type NodeInstance = {
  id: string;
  scope: InstanceScope;
};

export type BothNodeInstance = {
  byBlueprintId: {
    blueprintId: BlueprintId;
    blueprintIdScope: BlueprintIdInstanceScope;
  };
  byNodeId: {
    nodeId: NodeId | undefined;
    nodeIdScope: NodeIdInstanceScope;
  };
};

export type RepeatedAnswers<T extends string> = {
  repeatedIndex: InstanceIndex;
  answersByNodeId: Partial<Record<T, any>>;
};

type SurrogateId = string;

export type RepeatedAnswersBySurrogateId<T extends string> = Record<SurrogateId, RepeatedAnswers<T>>;
