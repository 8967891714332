import { t, Infer } from 'typegate';
import { ApplicationType } from './applicationType';
import { ApplicationMode } from './mode';
import { LineOfBusinessName } from '../linesOfBusiness';

export const createApplicationPayload = t
  .object(
    t.property('leadId', t.number),
    t.property('type', t.enum(ApplicationType)),
    t.property('mode', t.enum(ApplicationMode)),
    t.property('lineOfBusiness', t.enum(LineOfBusinessName)),
    t.property('questionnaireId', t.string),
    t.optionalProperty('mgaId', t.string),
    t.optionalProperty('userId', t.string),
  )
  .setTypeName('CreateApplicationPayload');

export type CreateApplicationPayload = Infer<typeof createApplicationPayload>;
